<template>
  <b-card body-class="p-0" header-class="border-0 transactions">
    <template v-slot:header>
      <b-row align-v="center" class="filter-inputs">
        <b-col sm="12" lg="2">
          <h3 class="mb-0">Total ({{ transactionsTotal }})</h3>
        </b-col>

        <b-col sm="12" lg="7" class="text-right col-center filter-selects">
          <b-row>
            <b-col sm="12" lg="6" class="data-list">
              <!-- Search input -->
              <b-form-input
                placeholder="Search"
                class="filter-input"
                v-bind:class="{ active: isActive, focused: isFocused }"
                @keyup="filterTableBySearch"
                @focus="focused"
              >
              </b-form-input>
            </b-col>
            <b-col sm="12" lg="6">
              <el-date-picker
                v-model="formattedFromDate"
                type="datetime"
                @change="filterDates($event)"
                placeholder="from"
                class="input-center"
              >
              </el-date-picker>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" lg="3" class="text-right report-buttons">
          <a href="#!" class="btn btn-sm btn-primary" @click="getCSV()"
            >Export CSV</a
          >
        </b-col>
      </b-row>
    </template>
    <spinner v-if="isLoading"></spinner>
    <el-table
      class="table-responsive table transactions"
      :data="displayData"
      header-row-class-name="thead-light"
      empty-text="No batches found"
      v-if="!isLoading"
      @sort-change="sortChange"
      style="width: 100% !important"
    >
      <el-table-column
        label="Distributor Name"
        min-width="90px"
        prop="Name"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Reference Number"
        min-width="90px"
        prop="theirRef"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Account Number"
        min-width="90px"
        prop="accountNumber"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Batch Amount"
        min-width="90px"
        prop="batchTotal"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Daily Amount"
        min-width="90px"
        prop="totalForDate"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="Total After Cutoff"
        min-width="70px"
        prop="totalAfterCutOff"
        sortable
      >
      </el-table-column>
      <el-table-column label="" min-width="35px">
        <template v-slot="{ row }">
          <button
            class="btn btn-sm btn-primary"
            :data-id="row.Id"
            @click="showModal(row)"
          >
            <span class="button-text">View</span>
            <span class="button-icon"><i class="far fa-eye"></i></span>
          </button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      :total="rows"
      :page-size="pageSize"
      align="right"
      v-if="!isLoading"
    >
    </el-pagination>
    <modal :show="isModalVisible" @close="closeModal">
      <div class="form">
        <p style="font-weight: 700">
          Batch details
          {{this.fromDate}}
     
        </p>
        <!-- display batch details here -->
        <div>
          <div>
            <b-row align-v="center">
              <b-col cols="12"><br /></b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4"><strong>Account Number</strong></b-col>
              <b-col cols="8" style="text-align: right">{{
                this.accountNumber
              }}</b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4"><strong>Reference Number</strong></b-col>
              <b-col cols="8" style="text-align: right">{{
                this.theirRef
              }}</b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4"><strong>Distributor Name</strong></b-col>
              <b-col cols="8" style="text-align: right">{{
                this.Name
              }}</b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4"><strong>Batch Total</strong></b-col>
              <b-col cols="8" style="text-align: right">{{
                this.batchTotal
              }}</b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4"><strong>Total For Date</strong></b-col>
              <b-col cols="8" style="text-align: right">{{
                this.totalForDate
              }}</b-col>
            </b-row>
            <b-row align-v="center">
              <b-col cols="4"><strong>Total After Cutoff</strong></b-col>
              <b-col cols="8" style="text-align: right">{{
                 this.totalAfterCutOff
              }}</b-col>
            </b-row>
          </div>
          <br />
          <b-button @click="closeModalHandler" type="submit" variant="primary"
            >OK</b-button
          >
        </div>
      </div>
    </modal>
  </b-card>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DatePicker,
  TimePicker,
  Pagination,
  Select,
  Option,
} from "element-ui";
import config from "../../config";
import Spinner from "../../components/Spinner.vue";
import { DateTime } from "luxon";

export default {
  name: "SummaryTable",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [DatePicker.name]: DatePicker,
    [TimePicker.name]: TimePicker,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
  },
  methods: {
    sortChange($event) {
      // Get sort change data
      const { prop, order } = $event;
      console.log($event);
      // Set sort direction order
      this.sortDirection = order == "ascending" ? "asc" : "desc";
      // Set sort filter type
      this.sort = prop;
      // Check for sort order and table data accordingly
      if (order == "ascending") {
        if (prop == "Name") {
          this.tableData = this.tableData.sort((a, b) =>
            a.Name > b.Name ? 1 : -1
          );
        }
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] > b[prop] ? 1 : -1
        );
      } else {
        if (prop == "Name") {
          this.tableData = this.tableData.sort((a, b) =>
            a.Name < b.Name ? 1 : -1
          );
        }
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] < b[prop] ? 1 : -1
        );
      }

      console.log("Table data:", this.tableData);
    },
    showModal(item) {
      console.log(item);
      this.supplierId = item.supplierId;
      this.ourRef = item.ourRef;
      this.theirRef = item.theirRef;
      this.batchStartTime = item.batchStartTime;
      this.batchEndTime = item.batchEndTime;
      this.Name = item.Name;
      this.accountNumber = item.accountNumber;
      this.branchCode = item.branchCode;
      this.payeeName = item.payeeName;
      this.batchTotal = item.batchTotal;
      this.totalForDate = item.totalForDate;
      this.totalAfterCutOff = item.totalAfterCutOff;
      this.isModalVisible = true;
    },
    closeModalHandler() {
      this.isModalVisible = false;
      this.supplierId = "";
      this.ourRef = "";
      this.theirRef = "";
      this.batchStartTime = "";
      this.batchEndTime = "";
      this.Name = "";
      this.accountNumber = "";
      this.branchCode = "";
      this.payeeName = "";
      this.batchTotal = "";
      this.totalForDate = "";
      this.totalAfterCutOff = "";
    },
    closeModal() {
      this.isModalVisible = false;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    dateFormatter(row, column, cellValue) {
      return this.formatDateISO(cellValue);
    },
    formatDate(cellValue) {
      var date = new Date(cellValue);
      return (
        ("0" + date.getDate()).slice(-2) +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        date.getFullYear() +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
    formatDateISO(inputDate) {
      var date = new Date(inputDate);
      return (
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
    formatCurrency(cellValue) {
      return parseFloat(cellValue).toLocaleString("en-ZA", {
        style: "currency",
        currency: "ZAR",
      });
    },
    percentageFormatter(row, column, cellValue) {
      return this.formatPercentage(cellValue);
    },
    formatPercentage(row, column, cellValue) {
      return parseFloat(cellValue).toFixed(2) + " %";
    },
    filterDates(event) {
      this.isLoading = true;

      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { Authorization: this.$store.getters["accounts/token"] },
      });
      http
        .post("/summary-reports/all", {
          fromDate: this.fromDate,
          sort: this.sort,
          sortOrder: this.sortDirection,
        })
        .then((response) => {
          console.log(JSON.stringify(response, null, 3));
          if (response.data.length > 0) {
            this.tableData = response.data;
            this.masterData = response.data;
            this.displayFilteredTableData();
            this.handleCurrentChange(1);
            this.isLoading = false;
            // Check if filter input has a value

            this.formatBatchesData(this.tableData);
            // Enable filter distributors input
            this.disabled = false;
          } else {
            this.tableData = [];
            this.masterData = [];
            // Enable filter distributors input
            this.disabled = true;
            this.handleCurrentChange(1);
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.tableData = [];
          this.masterData = [];
          // Enable filter distributors input
          this.disabled = true;
          this.handleCurrentChange(1);
          this.isLoading = false;
        });
    },
    displayFilteredTableData() {
      this.tableData = this.masterData.filter(
        (s) =>
          s.Name.includes(this.filter) ||
          s.accountNumber.toLowerCase().includes(this.filter) ||
          s.Name.toLowerCase().includes(this.filter) ||
          s.payeeName.toLowerCase().includes(this.filter) ||
          s.ourRef.toLowerCase().includes(this.filter)
      );
    },
    onFilterHandler() {
      // 1. Get fromDate (this.fromDate) & toDate (this.toDate)
      // 2. Format date to match server requirements
      // 3. Do async call here
      // 4. Populate table with response data
    },
    getCSV() {
      this.isLoading = true;
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 60000,
        headers: { Authorization: this.$store.getters["accounts/token"] },
      });

      const report = {
        fromDate: this.fromDate,
        filter: this.filter,
        sort: this.sort,
        sortOrder: this.sortDirection,
      };

      http
        .post("/summary-reports/csv", report, {
          responseType: "blob",
        })
        .then((response) => {
          var newBlob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              newBlob,
              "Summary_Distributor_Recon_Report.csv"
            );
            return;
          }
          const data = window.URL.createObjectURL(newBlob);
          var link = document.createElement("a");
          link.style = "display: none;";
          link.href = data;
          link.download = "Summary_Distributor_Recon_Report.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.isLoading = false;
          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            this.isLoading = false;
          }, 100);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    filterTableBySearch($event) {
      // Get typed in value in input
      const query = $event.target.value.trimStart().toLowerCase();
      // Get pressed key code
      const pressKeyCode = $event.keyCode;
      // Store length of query
      const queryLength = query != "" ? query.length : 0;
      if (queryLength > 0) {
        // Show cancel icon
        this.showDataList = true;
        // When the backspace key is pressed
        if (pressKeyCode == 8) {
          // Get value in input once again
          const value = $event.target.value.trimStart().toLowerCase();
          // Filter data
         this.filterTableData(value);
        }
        // Filter clients data
       this.filterTableData(query);
      } else {
        //  // Show cancel icon
        // this.showDataList = false;
        // Reset table data when there is no value in input
        this.resetTableData();
      }
    },
    filterTableData(query = "") {
      // Filter clients by search query
      const filteredData = this.masterData.filter(
        (c) =>{
          c.Name.toLowerCase().includes(query.toLowerCase()) ||
          c.accountNumber.toString().toLowerCase().includes(query.toLowerCase()) ||
          c.supplierId.toString().toLowerCase().includes(query.toLowerCase())
        }
      );
      // Store the filtered data as the new tatble data values
      this.tableData = filteredData;
    },
    resetTableData() {
      // Store clients from store and update table data
      this.formatBatchesData(this.tableData);
      // Reset table data to show master data
      this.tableData = this.masterData;
    },
    formatBatchesData(batchTrx) {
      // Convert data from observable to normal array
      const formattedTransactions = JSON.parse(JSON.stringify(batchTrx));
      // Key for getting unique values
      const key = "Name";
      // Create a set so that data only has unique values before we push it into dataList
      const uniqueTransactions = [
        ...new Map(
          formattedTransactions.map((item) => [item[key], item])
        ).values(),
      ];
      // Store the unique batchTrx in the dataList array
      this.dataList = JSON.parse(JSON.stringify(uniqueTransactions));
    },
    cancelFilterBySearch() {
      // Get the search input and set it to null/empty
      const input = (this.$refs.filterinput.$el.value = "");
      // Remove focused class
      this.isFocused = false;
      // Remove active class
      this.isActive = false;
      // Reset table data
      this.resetTableData();
    },
    removeCancelSearchIcon($event) {
      // Get typed in value in input
      const queryLength = $event.target.value.trimStart().length;
      // Remove cancel icons based on length value length
      if (queryLength == 0) {
        // Reverse is active
        this.isActive = false;
        // Reverse is focused
        this.isFocused = false;
      }
    },
    formatClientName(clientName) {
      return clientName.slice(0, 4).trimEnd() + "...";
    },
    removeReportPeriod(id) {
      // Convert string to JSON
      const formattedSuppliers = JSON.parse(JSON.stringify(this.clients));
      // Get index of selected time
      const filteredArray = formattedSuppliers.filter(
        (p) => p.supplierName !== supplierName
      );
      // Removed index array
      this.clients = filteredArray;
      // Remove id from report suppliers
      this.reportSupplierIds = this.reportSupplierIds.filter((i) => i != id);
    },
    blur($event) {
      console.log($event);
      // Remove focused class on search
      this.isFocused = false;
      // Close data list dive
      this.showDataList = false;
    },
    focused() {
      // Set focused class on search input wrapper
      this.isFocused = true;
      // Open data list div
      this.showDataList = true;
    },
    /*
          Method modified
      */
    selectedDistributor(supplierNames) {
      // keep data list showing
      this.showDataList = true;
      // Empty text string
      this.filter = supplierNames.target.value.trimStart();
      // Store query value
      const query = supplierNames.target.value.trimStart();
      // Close the data list
      this.showDataList = false;
      // Remove focus class
      this.isFocused = false;
      // Filtered array
      const filteredArray = [];
      // Split entered values
      const suppliers = supplierNames.target.value.split(",");
      // Loop through names to filter table data
      for (var i = 0; i < suppliers.length; i++) {
        // filter term formatting
        const filter = suppliers[i].toLowerCase().trimStart();
        // Fitler table data here
        this.tableData = this.masterData
          .filter(
            (p) =>
              p.supplierName.toLowerCase().includes(filter) ||
              p.clientName.toLowerCase().includes(filter) ||
              p.Name.toLowerCase().includes(filter) ||
              p.Type.toLowerCase().includes(filter)
          )
          .concat(filteredArray);
      }

      console.log("Filter:", this.tableData);
    },
  },
  data() {
    return {
      filter: "",
      sort: "",
      sortDirection: "",
      isActive: false,
      isFocused: false,
      showDataList: false,
      dataList: [],
      disabled: true,
      isModalVisible: false,
      transactionNumber: "-",
      currentPage: 1,
      pageSize: 10,
      supplierId: "",
      ourRef: "",
      theirRef: "",
      batchStartTime: "",
      batchEndTime: "",
      Name: "",
      accountNumber: "",
      branchCode: "",
      payeeName: "",
      batchTotal: "",
      totalForDate: "",
      totalAfterCutOff: "",
      fromDate: DateTime.now().startOf("day").toJSDate(),
      toDate: DateTime.now().endOf("day").toJSDate(),
      isLoading: false,
      isDetailLoading: true,
      tableData: [
        {
          supplierId: 5,
          ourRef: "20220301-5-1",
          theirRef: "20220301-5-1",
          batchStartTime: "2022-02-28 18:30:00",
          batchEndTime: "2022-03-01 18:30:00",
          Name: "Top1 Distributor",
          accountNumber: "123456789",
          branchCode: null,
          payeeName: "",
          batchTotal: 200,
          totalForDate: 200,
          totalAfterCutOff: 0,
        },
      ],
      masterData: [],
      fields: [
        {
          key: "Amount",
          label: "value(R$)",
          sortable: true,
          formatter: (value, key, item) =>
            value.toLocaleString("en-ZA", { style: "currency", currency: "R" }),
        },
      ],
    };
  },
  computed: {
    formattedFromDate: {
      get: function () {
        return this.formatDateISO(this.fromDate);
      },
      set: function (newValue) {
        this.fromDate = newValue;
      },
    },
    formattedToDate: {
      get: function () {
        return this.formatDateISO(this.toDate);
      },
      set: function (newValue) {
        this.toDate = newValue;
      },
    },
    rows() {
      return this.tableData.length;
    },
    displayData() {
      if (!this.tableData || this.tableData.length === 0) return [];
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
    transactionsTotal() {
      var total = 0;
      for (var i = 0; i < this.tableData.length; i++) {
        total += parseFloat(this.tableData[i].batchTotal);
      }
      return this.formatCurrency(total);
    },
    loggedinUserId() {
      return this.$store.getters["accounts/user"].Id;
    },
  },
  mounted() {
    this.isLoading = true;
    // Check if there are query passed to screen
    if (Object.keys(this.$route.query).length > 0) {
      console.log("Query:", this.$route.query);
      // Get query values
      const { filter, fromDate, sort, sortOrder } = this.$route.query;
      // Set values
      this.fromDate = new Date(fromDate);
      this.filter = filter;
      this.sortOrder = sortOrder;
      this.sort = sort;
    }

    var http = this.axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: this.$store.getters["accounts/token"] },
    });
    // Get reports
    http
      .post("/summary-reports/all", {
        fromDate: this.formattedFromDate,
        sort: this.sort,
        sortOrder: this.sortDirection,
      })
      .then((response) => {
        this.tableData = response.data;
        this.masterData = response.data;
        this.handleCurrentChange(1);
        this.isLoading = false;
      })
      .catch((err) => {
        this.tableData = [];
        this.masterData = [];
        this.handleCurrentChange(1);
        this.isLoading = false;
      });
  },
};
</script>
<style>
.col-center {
  display: flex;
  justify-content: space-between;
}

.data-list input.focused {
  border-color: #f7941d !important;
}

.input-center {
  margin: 0 10px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
  margin: 0 !important;
}

.filter-input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-size: 15px !important;
}

.data-list {
  position: relative;
}

.data-list .list {
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: 999999;
  color: #606266;
  border: 1px solid #e4e7ed;
  -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 4px;
  line-height: 30px;
  margin: 5px 0;
}

.data-list .list {
  list-style: none;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.data-list .list li {
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
}

.data-list .list li:hover {
  background-color: #f5f5f5;
}

.selected-data {
  min-height: 20px;
}

.periods {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
}

span.time {
  background: #f7941d;
  border-radius: 25px;
  font-size: 12px;
  color: #fff !important;
  position: relative;
  max-width: 60px;
  display: flex;
  align-items: center;
  padding: 4px;
  font-weight: 600;
  display: flex;
  min-height: 22px;
  min-width: 60px;
  margin-right: 10px;

  margin-bottom: 7px;
}

span.time .text {
  padding-left: 5px;
  line-height: 0 !important;
  display: inline-block;
  margin-bottom: -1px;
  padding-left: 5px;
  font-size: 11px;
  margin-left: -3px;
}

.time .remove {
  display: flex;
  border-radius: 50%;
  background: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 4px;
  cursor: pointer;
  color: #f7941d;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
</style>
